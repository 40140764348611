import {
  homeContents,
  teamContents,
  portfolioContents
} from "./bannerContents";

interface Content {
  title: string;
  subtitle: string;
  text: string;
  backgroundUrl: string;
}

let index: number = 0;
const banner = document.getElementById("banner");
const bannerBackground = document.getElementById("banner__background");
const bannerTitle = document.getElementById("banner__title");
const bannerSubTitle = document.getElementById("banner__sub-title");
const bannerContent = document.getElementById("banner__content");
const categoryExclude = banner.dataset.page === "home" ? 4 : 3;
let contents: Content[] =
  banner.dataset.page === "home"
    ? homeContents
    : banner.dataset.page === "portfolio"
      ? portfolioContents
      : teamContents;

document.getElementById("banner").addEventListener("click", () => nextSlide(contents));
// document.getElementById("banner__next").addEventListener("click", () => nextSlide(contents));
// document.getElementById("banner__prev").addEventListener("click", () => prevSlide(contents));
printContents(contents[index]);
index++;
let interval = setInterval(() => {
  nextSlide(contents)
}, 10000);

fetch(
  "https://seislados.com.ar/cms/wp-json/wp/v2/posts?categories=1&categories_exclude=" +
  categoryExclude
)
  .then(r => r.json())
  .then(posts => {
    posts.map(post => {
      const title: string = purgeSymbols(post.title.rendered).replace(
        /\ /g,
        ""
      );
      const subTitle: string = purgeSymbols(post.content.rendered).replace(
        /\ /g,
        ""
      );
      const text: string = purgeSymbols(post.excerpt.rendered);
      contents.push({
        title: title,
        subtitle: subTitle,
        text: text,
        backgroundUrl: post["better_featured_image"].source_url
      });
    });
  })
  .then(() => {
    console.log("contents fetched");
    console.log(contents.length);

    document.getElementById("banner__next").style.display = "block";
    document.getElementById("banner__prev").style.display = "block";
  })
  .catch(err => console.error("Error: ", err));

function purgeSymbols(text: string): string {
  text = text.replace(/<p>/g, "");
  text = text.replace(/<\/p>/g, "");
  text = text.replace(/<br>/g, "");
  text = text.replace(/<br\/>/g, "");
  text = text.replace(/<br \/>/g, "");
  text = text.replace(/\\n/g, "");
  return text;
}
function printContents(content: Content) {
  if (banner) {
    console.log(content);

    bannerTitle.style["opacity"] = "0";
    bannerSubTitle.style["opacity"] = "0";
    bannerContent.style["opacity"] = "0";
    bannerBackground.style["filter"] = "opacity(0%)";
    setTimeout(() => {
      bannerBackground.style.backgroundImage =
        "url(" + content.backgroundUrl + ")";
      bannerTitle.innerText = content.title;
      bannerSubTitle.innerText = content.subtitle;
      bannerContent.innerText = content.text;
      setTimeout(() => {
        bannerBackground.style["filter"] = "opacity(100%) grayscale(1)";
        bannerTitle.style["opacity"] = "1";
        bannerSubTitle.style["opacity"] = "1";
        bannerContent.style["opacity"] = "1";
      }, 400);
    }, 300);
  }
}

function nextSlide(contents: Content[]) {
  clearInterval(interval);
  index++;
  index = index >= contents.length ? 0 : index;
  console.log(index);
  printContents(contents[index]);
  interval = setInterval(() => {
    nextSlide(contents)
  }, 10000);
}
// function prevSlide(contents: Content[]) {
//   clearInterval(interval);
//   index--;
//   index = index <= 0 ? contents.length - 1 : index;
//   console.log(index);
//   printContents(contents[index]);
//   interval = setInterval(() => {
//     nextSlide(contents)
//   }, 10000);
// }
