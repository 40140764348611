const sidenav = document.getElementById("sidenav");
const sidenavBtn = document.getElementById("sidenav-btn");
const sidenavBg = document.getElementById("sidenav-bg");
const closeSideNav = document.getElementById("close_nav");

sidenavBtn.addEventListener("click", () => {
  sideNavToggle();
});

closeSideNav.addEventListener("click", () => {
  sideNavToggle();
});

function sideNavToggle() {
  sidenav.classList.toggle("sidenav--expanded");
  closeSideNav.classList.toggle("close_nav--expanded");
}
