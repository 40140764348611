import LazyLoad from "vanilla-lazyload";
import chunk from "lodash.chunk";
const lazyLoadInstance = new LazyLoad({
  elements_selector: ".lazy"
});

let currentPosts = [];
let currentPostsIndex: number = 0;
const postsContainer = document.getElementById("posts");
const postsTop = document.getElementById("posts-top");

function renderCard(post: any) {
  post.content.rendered = post.content.rendered.replace(/<p>/g, "");
  post.content.rendered = post.content.rendered.replace(/src/g, "data-src");
  post.content.rendered = post.content.rendered.replace(/wp-image/g, "lazy ");
  post.content.rendered = post.content.rendered.replace(/<\/p>/g, "<br>");
  post.excerpt.rendered = post.excerpt.rendered.replace(/<p>/g, "");
  post.excerpt.rendered = post.excerpt.rendered.replace(/<\/p>/g, "<br>");
  const modifier = (post.number + 1) % 2 ? " card--reverse" : "";
  return `
  <article class="card${modifier}">
    <button class="card__button"><svg class="icon-white" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><path class="icon__path" d="M38 26H26v12h-4V26H10v-4h12V10h4v12h12v4z"/></svg></button>
    <img class="card__img lazy" data-src="${
      post["better_featured_image"].source_url
    }" 
    alt="${post["better_featured_image"].alt_text}">
            <section class="card__content">
            <h3 class="card__title blue">
              ${post.title.rendered}
              </h3>
              <p class="card__text card__text-excerpt">
              ${post.excerpt.rendered}
              </p>
              <p class="card__text card__text-content">
              ${post.content.rendered}
              </p>
              </section>
              </article>`;
}
const BODY = document.getElementById("body");
function enableModalCards() {
  const cards = document.querySelectorAll(".card");
  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];
    card.addEventListener("click", () => {
      card.classList.toggle("card--modal");
      BODY.classList.toggle("body--no-scroll");
    });
  }
}

function loadPosts() {
  if (postsContainer) {
    const postType = postsContainer.dataset.postType;
    fetch(
      "https://seislados.com.ar/cms/wp-json/wp/v2/posts?categories=" +
        postType +
        "&categories_exclude=1"
    )
      .then(r => r.json())
      .then(posts => {
        currentPosts = chunk(posts, 3);
      })
      .then(() => {
        renderPosts(0, true);
      });
  }
}

function cardsInit() {
  enableModalCards();
  lazyLoadInstance.update();
}

function renderPosts(index: number, newBtn: boolean) {
  makeCards(index, newBtn);
}

function makeCards(index: number, newBtn: boolean) {
  let count = 0;
  postsContainer.innerHTML = "";
  for (let i = 0; i < currentPosts[index].length; i++) {
    const post = currentPosts[index][i];
    post.number = ++count;
    postsContainer.innerHTML += renderCard(post);
  }
  postsContainer.innerHTML += "<br /><br />";
  currentPostsIndex = index;
  cardsInit();
  renderPagination(index);
  if (newBtn) {
  }
}

function renderPagination(current: number) {
  const firstElement = document.getElementById("pagination-first");
  const previousElement = document.getElementById("pagination-previous");
  const nextElement = document.getElementById("pagination-next");
  const lastElement = document.getElementById("pagination-last");

  firstElement.dataset.index = "0";
  previousElement.dataset.index = (currentPostsIndex - 1 >= 0
    ? currentPostsIndex - 1
    : currentPostsIndex
  ).toString();
  nextElement.dataset.index = (currentPostsIndex + 1 <= currentPosts.length - 1
    ? currentPostsIndex + 1
    : currentPostsIndex
  ).toString();
  lastElement.dataset.index = (currentPosts.length - 1).toString();
  const removableBtnS = document.querySelectorAll(
    ".pagination__button--removable"
  );

  for (let i = 0; i < removableBtnS.length; i++) {
    const element = removableBtnS[i];
    element.remove();
  }

  for (let i = 0; i < currentPosts.length; i++) {
    insertAfter(createBtn((i + 1).toString(), current === i), nextElement);
  }
  const buttons = document.querySelectorAll(".pagination__button");
  for (let i = 0; i < buttons.length; i++) {
    const BTN = buttons[i];
    BTN.addEventListener("click", function(this) {
      postsTop.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      setTimeout(() => {
        renderPosts(+this.dataset.index, false);
      }, 500);
    });
  }
}
function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode);
}

function createBtn(content: string, active: boolean = false) {
  const btn = document.createElement("button");
  btn.innerHTML = content;
  btn.className = "pagination__button";
  btn.classList.add("pagination__button--removable");
  if (active) {
    btn.classList.add("pagination__button--active");
  }
  btn.dataset.index = (+content - 1).toString();
  return btn;
}

window.addEventListener("load", () => {
  loadPosts();
});
