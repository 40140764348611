import validator from "validator";
import DOMPurify from "dompurify";
const form = document.getElementById("form");

if (form) {
  form.addEventListener(
    "submit",
    function(event) {
      event.preventDefault();
      const name = <HTMLInputElement>document.getElementById("name");
      const phone = <HTMLInputElement>document.getElementById("phone");
      const email = <HTMLInputElement>document.getElementById("email");
      const body = <HTMLTextAreaElement>document.getElementById("text");
      const nameText = DOMPurify.sanitize(name.value);
      const phoneText = DOMPurify.sanitize(phone.value);
      const emailText = DOMPurify.sanitize(email.value);
      const bodyText = DOMPurify.sanitize(body.value);

      let error = false;
      if (validator.isEmpty(nameText)) {
        error = true;
        name.focus();
        alert("Por favor ingresa tu nombre.");
        return;
      }
      if (!validator.isInt(phoneText)) {
        error = true;
        phone.focus();
        alert("Por favor ingresa un número de teléfono válido.");
        return;
      }
      if (!validator.isEmail(emailText)) {
        error = true;
        email.focus();
        alert("Por favor ingresa un correo electrónico válido.");
        return;
      }
      if (validator.isEmpty(bodyText)) {
        error = true;
        body.focus();
        alert("Por favor ingresa tu consulta.");
        return;
      }

      if (!error) {
        fetch("https://seislados.com.ar/services/mail.php", {
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
          },
          // body: JSON.stringify({
          //   name: nameText,
          //   phone: phoneText,
          //   email: emailText,
          //   body: bodyText
          // })
          body: `name=${nameText}&phone=${phoneText}&email=${emailText}&body=${bodyText}`
        })
          // .then(res => res.json())
          .then(response => {
            // console.log("Success:", JSON.stringify(response));
            alert("Consulta enviada exitosamente.");
          })
          .catch(error => console.error("Error:", error));
      }
    },
    false
  );
}
