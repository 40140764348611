import Glide from "@glidejs/glide";

if (document.getElementById("glide-partners")) {
  new Glide("#glide-partners", {
    type: "carousel",
    startAt: 0,
    perView: 3,
    autoplay: 2000,
    hoverpause: true
  }).mount();
}
