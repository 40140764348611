import homeImage from "/assets/images/banner/home.jpg";
import portfolioImage from "/assets/images/banner/portfolio.jpg";
import teamImage from "/assets/images/banner/team.jpg";

const homeContents = [
  {
    title: "Inversiones",
    subtitle: "Conscientes",
    text: "Desarrollamos proyectos sustentables que aseguren rentabilidad para los ecosistemas y tus inversiones.",
    backgroundUrl: homeImage
  }
];

const teamContents = [
  {
    title: "Alejandro",
    subtitle: "Mazzoni",
    text: "",
    backgroundUrl: teamImage
  }
];

const portfolioContents = [
  {
    title: "Inversiones",
    subtitle: "Consientes",
    text: "",
    backgroundUrl: portfolioImage
  }
];

export { homeContents, teamContents, portfolioContents };
